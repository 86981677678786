exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-colabora-tsx": () => import("./../../../src/pages/colabora.tsx" /* webpackChunkName: "component---src-pages-colabora-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-noticias-index-tsx": () => import("./../../../src/pages/noticias/index.tsx" /* webpackChunkName: "component---src-pages-noticias-index-tsx" */),
  "component---src-pages-noticias-mdx-frontmatter-slug-tsx-content-file-path-content-news-20240201-adolfo-del-antiguo-super-index-mdx": () => import("./../../../src/pages/noticias/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/cer-el-encinar.github.io/cer-el-encinar.github.io/content/news/20240201-adolfo-del-antiguo-super/index.mdx" /* webpackChunkName: "component---src-pages-noticias-mdx-frontmatter-slug-tsx-content-file-path-content-news-20240201-adolfo-del-antiguo-super-index-mdx" */),
  "component---src-pages-noticias-mdx-frontmatter-slug-tsx-content-file-path-content-news-20240301-campana-de-identifciacion-2024-index-mdx": () => import("./../../../src/pages/noticias/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/cer-el-encinar.github.io/cer-el-encinar.github.io/content/news/20240301-campana-de-identifciacion-2024/index.mdx" /* webpackChunkName: "component---src-pages-noticias-mdx-frontmatter-slug-tsx-content-file-path-content-news-20240301-campana-de-identifciacion-2024-index-mdx" */),
  "component---src-pages-privacidad-tsx": () => import("./../../../src/pages/privacidad.tsx" /* webpackChunkName: "component---src-pages-privacidad-tsx" */),
  "component---src-pages-terminos-tsx": () => import("./../../../src/pages/terminos.tsx" /* webpackChunkName: "component---src-pages-terminos-tsx" */)
}

